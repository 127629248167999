import { CSSProperties } from '@mui/material/styles/createMixins'

export const backgroundCSS: CSSProperties = {
  backdropFilter: 'blur(8px)',
  WebkitBackdropFilter: 'blur(8px)',
  '@supports (backdrop-filter: blur(20px))': {
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
  '@supports not (backdrop-filter: blur(20px))': {
    backgroundColor: 'rgba(0,0,0,0.8)',
  },
}
